const funcs = {
    parseUUID(urlPathname) {
        if (urlPathname === "" || urlPathname === "/") {
            return null
        } else {
            return urlPathname.split("/")[1]
        }
    }
}

export default funcs