import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './styles/style.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import { datadogRum } from '@datadog/browser-rum';

const theme = createTheme({
  palette: {
    primary: {
      main: "#69bbe1"
    },
    secondary: {
      main: "#757575"
    },
    generic: {
      main: "white"
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// track aapplication via DataDog
datadogRum.init({
    applicationId: '939457c4-0ac9-42dd-9f6e-8a81d31dc119',
    clientToken: 'pub037f09b3076b3d42dddd714aa1b8791e',
    site: 'datadoghq.com',
    service:'golddigger',
    env: process.env.REACT_APP_ENV,
    sampleRate: 100,
    trackInteractions: true
});