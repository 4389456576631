import Footer from "./Footer";
import Header from "./Header";
import Survey from "./Survey";

function App() {
  return (
    <div className="App">
      <div className="Body">
        <Header />
        <Survey />
        <Footer />
      </div>
    </div>
  );
}

export default App;
