import { Button, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { prompts } from "../data";
import funcs from "../utilities/funcs";
import api from "../utilities/api";
import CircularProgress from "@material-ui/core/CircularProgress";

const Survey = ({ currentQuestionState = "landing", sb = false }) => {
    const useStyles = makeStyles({
        root: {
            margin: "0.5rem",
            height: "2.3rem",
            width: "6rem"
        },
        label: {
            color: "white"
        }
    })
    
    const classes = useStyles()
    const [isLoaded, setIsLoaded] = useState(false)
    const [isWaitingYes, setIsWaitingYes] = useState(false)
    const [isWaitingNo, setIsWaitingNo] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState(currentQuestionState)

    const patientID = funcs.parseUUID(window.location.pathname)

    let endpoint

    if (process.env.REACT_APP_ENV === "prod") {
    	endpoint = "https://api-dev.aptsleep.com/survey"
    } else if (process.env.REACT_APP_ENV === "test") {
    	endpoint = "https://api-dev.aptsleep.net/survey"
    } else if (process.env.REACT_APP_ENV === "dev") {
    	endpoint = "https://api-dev.aptsleep.de/survey"
    } else {
        endpoint = "https://api-dev.aptsleep.de/survey"
    }

    useEffect(() => {
        if (!sb) {
            // Check status of survey in DB
            (async () => {
                const result = await api.getSurvey(patientID)
                if (result.error) {
                    if (result.error === "InvalidRequest") {
                        setCurrentQuestion("400error")
                        setIsLoaded(true)
                    } else if (result.error === "PatientNotFound") {
                        setCurrentQuestion("404error")
                        setIsLoaded(true)
                    } else if (result.error === "SurveyAlreadyCompleted") {
                        setCurrentQuestion("403error")
                        setIsLoaded(true)
                    } else {
                        setCurrentQuestion("500error")
                        setIsLoaded(true)
                    }
                } else {
                    setIsLoaded(true)
                }
            })()
        }
    }, [sb, patientID])

    const questions = {
        "landing": 
            <>
                {prompts["landing"]}
                <div className="buttons">
                    <Button 
                        variant="contained" 
                        color="primary" 
                        classes={{root: classes.root, label: classes.label}}
                        disabled={isWaitingYes}
                        onClick={async () => {
                            if (!sb) {
                                const answers = {
                                    assessment_requested: true
                                }
                                setIsWaitingYes(true)
                                const result = await api.submit(patientID, answers, endpoint)
                                if (result.error) {
                                    if (result.error === "PatientNotFound") {
                                        setCurrentQuestion("404error")
                                    } else if (result.error === "SurveyAlreadyCompleted") {
                                        setCurrentQuestion("403error")
                                    } else {
                                        setCurrentQuestion("500error")
                                    }
                                    setIsWaitingYes(false)
                                } else {
                                    setCurrentQuestion("ansYes")
                                    setIsWaitingYes(false)
                                }
                            }
                        }}
                    >{ isWaitingYes ? <CircularProgress size={20} color="generic"/> : "Yes" }</Button>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        classes={{root: classes.root, label: classes.label}}
                        disabled={isWaitingNo}
                        onClick={async () => {
                            if (!sb) {
                                const answers = {
                                    assessment_requested: false
                                }
                                setIsWaitingNo(true)
                                const result = await api.submit(patientID, answers, endpoint)
                                if (result.error) {
                                    if (result.error === "PatientNotFound") {
                                        setCurrentQuestion("404error")
                                    } else if (result.error === "SurveyAlreadyCompleted") {
                                        setCurrentQuestion("403error")
                                    } else {
                                        setCurrentQuestion("500error")
                                    }
                                    setIsWaitingNo(false)
                                } else {
                                    setCurrentQuestion("ansNo")
                                    setIsWaitingNo(false)
                                }
                            }
                        }}
                    >{ isWaitingNo ? <CircularProgress size={20} color="generic"/> : "No" }</Button>
                </div>
            </>,
        "ansYes": prompts["ansYes"],
        "ansNo": prompts["ansNo"],
        "500error":
            <>
                <h1 className="thank-you">500 Error</h1>
                <p>Internal server error. Please try again later.</p>
            </>,
        "400error":
            <>
                <h1 className="thank-you">400 Error</h1>
                <p>Invalid request.</p>
            </>,
        "403error": 
            <>
                <p data-testid="surveyAlreadyComplete">Survey already completed. Please reach out at any time if you have any questions.</p>
            </>,
        "404error":
            <>
                <h1 className="thank-you">404 Error</h1>
                <p>Survey not found. Please contact Aeroflow Sleep with any questions.</p>
            </>
    }

    if (sb) return (
        <div className="survey-container">
            <div className="survey">
                { questions[currentQuestion] }
            </div>
        </div>
    )

    if (!isLoaded) return (
        <div className="survey-container">
            <div className="survey">
                <CircularProgress color="primary" />
            </div>
        </div>
    )

    return (
        <div className="survey-container">
            <div className="survey">
                { questions[currentQuestion] }
            </div>
        </div>
    )
}

export default Survey