const api = {
    async submit(id, answers, url) {
        console.log("in submit: ", url)
        let result
        await fetch(url, {
            method: "POST",
            body: JSON.stringify({
                patient_id: id,
                answers: answers
            })
        })
        .then(res => res.json())
        .then(data => {
            result = data
        })
        return result
    },
    async update(id, answers, isFirstQuestion=false) {
        let result
        if (isFirstQuestion) {
            await fetch(`https://api-dev.aptsleep.de/survey/${id}`, {
                method: "PUT",
                body: JSON.stringify({
                    patient_id: id,
                    survey_status: "in-progress"
                })
            })
            .then(res => res.json())
            .then(data => {
                result = data
            })
        } else {
            await fetch(`https://api-dev.aptsleep.de/survey/${id}`, {
                method: "PUT",
                body: JSON.stringify({
                    patient_id: id,
                    answers: answers
                })
            })
            .then(res => res.json())
            .then(data => {
                result = data
            })
        }
        return result
    },
    async getSurvey(id) {
        let result
        await fetch(`https://api-dev.aptsleep.de/survey/${id}`, {
            method: "GET",
        })
        .then(res => res.json())
        .then(data => {
            result = data
        })
        return result
    }
}

export default api