import logo from '../images/logo_aeroflow.png'

const Header = () => {
    return (
        <div className="header">
            <img src={logo} alt="aeroflow"/>
            <h1>PAP Therapy Survey</h1>
        </div>
    )
}

export default Header
