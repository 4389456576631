export const prompts = {
    "landing": 
        <>
            <p>
                Hello, this is your sleep specialist at Aeroflow Sleep. We have an exciting new capability 
                that we hope will help you get the most benefit from your PAP treatment.
            </p>
            <p>
                Comfort and effective treatment of your obstructive sleep apnea depends upon you having the latest and properly fitting mask. 
                If you have been using the same mask for years, or stopped using your PAP therapy as often as you should because of discomfort 
                from your mask, Aeroflow Sleep now has a tool which will allow us to assess your needs and select the best available 
                PAP mask for you.
            </p>
            <p>
                The virtual fitting takes less than 3 minutes, can be done on your phone from the comfort of your home, and costs you nothing.
            </p>
            <p>
                Would you like to have us conduct virtual assessment of your perfect PAP mask?
            </p>
        </>,
    "ansYes":
        <>
            <p>
                Great, you will receive a text from us shortly with a link to our virtual mask assessment tool.
            </p>
        </>,
    "ansNo":
        <>
            <p>
                Got it. Please reach out to us at any time if you change your mind. There are new masks coming out all 
                of the time and having the best possible mask fit is essential to effective treatment of your sleep apnea.
            </p>
        </>
}